import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function adminFood() {
  const food = [
    {
      name: "Lunch Day 1",
      link: "/admin/adminscanqr/lunch1",
    },
    {
      name: "Dinner Day 1",
      link: "/admin/adminscanqr/dinner",
    },
    // {
    //   name: "Midnight Snacks",
    //   link: "/admin/adminscanqr/midnight",
    // },
    {
      name: "BreakFast Day 2",
      link: "/admin/adminscanqr/breakfast",
    },
    {
      name: "Lunch Day 2",
      link: "/admin/adminscanqr/lunch2",
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3">
      {food.map((item) => {
        return (
          <Link to={item.link} key={item.link}>
            <div className="card">{item.name}</div>
          </Link>
        );
      })}
    </div>
  );
}

export default adminFood;
