import React from 'react'
import { Link } from 'react-router-dom'

function Others() {
    return (
        <div className='flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3'>
            <Link to="https://drive.google.com/file/d/1hhf57oiJCIErq4ZawCQCaiCEdWlQ7hMu/view?usp=sharing">
                <div className='card'>
                    Code of Conduct
                </div>
            </Link>
            <Link to="/e">
                <div className='card'>
                    Feedback
                </div>
            </Link>
            <Link to="https://drive.google.com/drive/folders/1DoyRnRXdSbaIFNBtUgRs9Sxq2664EGxE?usp=sharing">
                <div className='card'>
                    E-Certifcates
                </div>
            </Link>
            <Link to="/user/mentor">
                <div className='card'>
                    Mentors
                </div>
            </Link>
            <Link to="/user/brigade">
                <div className='card'>
                    Fire Brigadiers
                </div>
            </Link>
        </div>
    )
}

export default Others
/***
 *     <Link to="https://drive.google.com/file/d/1qo6WQOPhraem-0bkjiJPLo8R1sqjy1Qf/view?usp=sharing">
                <div className='card'>
                    App User Manual
                </div>
            </Link>
 */