import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../api";
import { useNavigate } from "react-router-dom";
import SpinLoader from "../components/spinLoader";

const UserRoutes = () => {
  const navigate = useNavigate();
  const [ok, setOk] = useState(false);
  const [auth, setAuth] = useAuth();
  // console.log(ok, auth);
  useEffect(() => {
    const authCheck = async () => {
      try {
        const res = await axios.get(baseUrl + "/protected");
        if (res.data.ok) {
          setOk(true);
        } else {
          localStorage.clear();
          window.location.href = "/";
        }
      } catch (error) {
        localStorage.clear();
        window.location.href = "/";
      }
    };
    if (auth?.token) authCheck();
    if (!localStorage.getItem("auth")) navigate("/");
  }, [auth?.token]);
  return (
    <>
      {ok == true && <Outlet />}
      {ok == false && <SpinLoader />}
    </>
  );
};

export default UserRoutes;
