import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../api";
import { useAuth } from "../context/AuthContext";
import HashLoader from "react-spinners/HashLoader";
import SpinLoader from "../components/spinLoader";

function Food() {
  const food_alias = {
    lunch1: "Lunch Day 1",
    snacks: "Snacks Day 1",
    dinner: "Dinner Day 1",
    midnight: "Midnight Snacks",
    breakfast: "BreakFast Day 2",
    lunch2: "Lunch Day 2",
  };

  const [food, setFood] = useState([]);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await axios.get(baseUrl + "/get_user/" + auth.id);
        const data = res.data.user.food;
        setFood(data);
        setLoading(false);
      } catch (err) {
        alert(err.message);
      }
    };
    fetchData();
  }, []);

  // console.log(food);

  const renderFood = (food) => {
    let arr = [];
    for (const item of Object.keys(food)) {
      // console.log(item);

      if(item === "midnight")
        continue;

      if (food[item] === false) {
        arr.push(
          <Link to="/user/myqr" key={item}>
            <div className="card">{food_alias[item]}</div>
          </Link>
        );
      } else {
        arr.push(
          // <Link to="/user/myqr" key={item}>
          <div
            className="card"
            style={{ border: "solid", borderColor: "green" }}
          >
            {food_alias[item]}
          </div>
          // </Link>
        );
      }
    }
    // console.log(arr);
    return arr;
  };

  return (
    <>
      {loading && <SpinLoader />}
      <div className="flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3">
        {/* <HashLoader
        color={"#AB433A"}
        loading={loading}
        css={{ display: "block", margin: "0 auto" }}
        size={40}
      /> */}
        {renderFood(food)}
      </div>
    </>
  );
}

export default Food;
