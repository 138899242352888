import React from 'react'
import { Link } from 'react-router-dom'

function ProblemStatement() {
    return (
        <div className='flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3'>
            <div className=''>
                Domains
            </div>
            <Link>
                <div className='card'>
                    Web/App
                </div>
            </Link>
            <Link>
                <div className='card'>
                    AI/ML
                </div>
            </Link>
            <Link>
                <div className='card'>
                    Block Chain
                </div>
            </Link>
            <div className=''>
                Forms
            </div>
            <Link>
                <div className='card'>
                    Problem Statement Preference
                </div>
            </Link>
            <Link>
                <div className='card'>
                    Certificates
                </div>
            </Link>
        </div>
    )
}

export default ProblemStatement
