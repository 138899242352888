import { QrScanner } from "@yudiel/react-qr-scanner";
import React, { useState } from "react";
import { baseUrl } from "../../api";
import axios from "axios";
import { useParams } from "react-router-dom";

const AdminScanQR = () => {
  const { food } = useParams();

  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("");
  const handleDecode = async (result) => {
    setResult(JSON.parse(result));
    const newResult = JSON.parse(result);
    try {
      const response = await axios.put(baseUrl + "/scanqr", {
        id: newResult.user_id,
        food: food,
      });
      console.log(response);
      setMessage(response.data.message);
    } catch (error) {
      console.log(error);
      setMessage(error.response ? error.response.data.message : error.message);
    }
  };

  const handleError = (error) => {
    console.log(error?.message);
  };

  const setReset = () => {
    setResult(null);
    setMessage("");
  };

  const previewStyle = {
    height: 700,
  }

  const videoStyle = {
    height: 700,
    width: 400,
  }


  return (
    <div className="h-[100vh] flex flex-col justify-start items-center gap-4 text-3xl px-3">
      
        {!result ? (
          <QrScanner
            onDecode={handleDecode}
            onError={handleError}
            containerStyle={previewStyle}
            videoStyle={videoStyle}
            constraints={{
              facingMode: "environment",
            }}
          />
        ) : (
          <>
            {message}
            {message !== "" && (
              <button className="card-button" onClick={setReset}>
                Re-scan
              </button>
            )}
          </>
        )}
    </div>
  );
};

export default AdminScanQR;
