import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./pages/home";
import TimeLine from "./pages/timeLine";
import ProblemStatement from "./pages/problemStatement";
import Games from "./pages/game";
import Others from "./pages/other";
import Food from "./pages/food";
import MyQR from "./pages/myQR";
import AdminFood from "./pages/admin/adminFood";
import AdminScanQR from "./pages/admin/adminScanQR";
import Login from "./pages/login";
import ScreenSizeError from "./pages/screenSizeError";
import AdminRoutes from "./routes/adminRoutes";
import UserRoutes from "./routes/userRoutes";
import AdminHome from "./pages/admin/adminHome";
import FourOfour from "./pages/404";
import Mentor from "./pages/mentor";
import Brigade from "./pages/brigade";
function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const targetDate = new Date("December 26, 2023 14:59:59 GMT+05:30");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      {!isMobile ? (
        <ScreenSizeError />
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/user" element={<UserRoutes />}>
            <Route path="" element={<Home targetDate={targetDate} />} />
            <Route path="timeline" element={<TimeLine />} />
            <Route path="problemstatement" element={<ProblemStatement />} />
            <Route path="food" element={<Food />} />
            <Route path="games" element={<Games />} />
            <Route path="others" element={<Others />} />
            <Route path="myqr" element={<MyQR />} />
            <Route  path="/user/mentor" element={<Mentor />}/>
            <Route  path="/user/brigade" element={<Brigade />}/>
          </Route>
          <Route path="/admin" element={<AdminRoutes />}>
            <Route path="" element={<AdminHome targetDate={targetDate} />} />
            <Route path="adminfood" element={<AdminFood />} />
            <Route path="adminscanqr/:food" element={<AdminScanQR />} />
          </Route>
          <Route path="*" element={<FourOfour />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
