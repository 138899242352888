import React from "react";
import QRCode from "react-qr-code";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function MyQR( { foodType } ) {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const value = JSON.stringify({
    team_name: auth.teamName,
    user_name: auth.userName,
    user_id: auth.id,
  });

  function handleMove() {
    navigate("/user/food");
  }

  return (
    <div className="h-[100vh] flex flex-col justify-center items-center gap-4 text-3xl px-3">
      <h1 className="text-2xl">Hack Hard, Eat Well! </h1>
      <div style={{ width: "90%" }} className="p-4 bg-white rounded-md">
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 640,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <button className="card-button" onClick={handleMove}>
        Munch On
      </button>
    </div>
  );
}

export default MyQR;
