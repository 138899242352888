import React from "react";
import "./spinLoader.css"

function spinLoader() {
  return (
    <div className="h-[100vh]">
      
      <div className="h-[45vh]  justify-center flex flex-col items-center" >
           <img className="w-[80px] max-[300px]:w-[60px]"  src="/logo.png"  alt="technoPoint"/>
          <h1 className=" text-3xl text-center"> x</h1>
           <img className="w-[70vw] max-[399px]:w-[55vw]"  src="/technoPoint.png"  alt="technoPoint"/>
      </div>
      <div className="spin-loader">
        <div className="spin-inner"></div>
      </div>
    </div>
  );
}

export default spinLoader;
