import React from 'react'
import { Link } from 'react-router-dom'
function Mentor() {
    return (

        // Never use position: relative/abs pls 
        <div className='w-full justify-center items-center'>
            <div className='mt-[1rem] w-full flex items-center justify-center'>
                <div className='py-[.8rem] px-[2rem] rounded' >
                    <h1 className='text-white uppercase text-center text-[1.5rem]'>Mentors</h1>
                </div>
            </div>
            <div className='w-full mt-[2rem] flex flex-wrap items-center justify-center gap-x-2 gap-y-2   ' >

    {/* Card starts here */}
            <div className='h-[200px] overflow-hidden flex flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m1pic'>
                <Link to={"https://www.linkedin.com/in/darshan-rander/"} >
                <div className='pt-[.5rem] text-center'>
                        <div className='w-full whole_name'>
                            <div className='bg-white bg-opacity-70'>
                                <h3 text >Darshan Render</h3>
                                <p className='uppercase text-[#B70000]'>DEV/SOCIAL</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
                  
        {/* Card ends here */}

            <div className='h-[200px] overflow-hidden flex  flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m2pic'>
                <Link to={"https://www.linkedin.com/in/tusharnankani/"} >  
                    <div className='pt-[.5rem] text-center'>
                        <div className='w-full whole_name'>
                            <div className='bg-white bg-opacity-70'>
                                <h3 text >Tushar Nankani</h3>
                                <p className='uppercase text-[#B70000]'>DEV/SOCIAL</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>



                <div className='h-[200px]  overflow-hidden flex flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m3pic'>
                    <Link to={"https://www.linkedin.com/in/jaden-furtado/"} >
                        <div className='pt-[.5rem] text-center'>
                            <div className='w-full whole_name'>
                                <div className='bg-white bg-opacity-70'>
                                    <h3 text >Jaden Furtado</h3>
                                    <p className='uppercase text-[#B70000]'>DEV/SOCIAL</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>




                <div className='h-[200px]  overflow-hidden flex flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m4pic'>
                    <Link to={"https://www.linkedin.com/in/kryptoblack/"} >
                        <div className='pt-[.5rem] text-center'>
                            <div className='w-full whole_name'>
                                <div className='bg-white bg-opacity-70'>
                                    <h3 text >Pratik Thakare</h3>
                                    <p className='uppercase text-[#B70000]'>DEV/SOCIAL</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>



                    <div className='h-[200px] overflow-hidden flex flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m5pic'>
                        <Link to={"https://www.linkedin.com/in/hrithikwins"} >
                        <div className='pt-[.5rem] text-center'>
                            <div className='w-full whole_name'>
                                <div className='bg-white bg-opacity-70'>
                                    <h3 text >Hritik Tiwari</h3>
                                    <p className='uppercase text-[#B70000]'>DEV/SOCIAL</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>



                <div className='h-[200px] overflow-hidden flex flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m6pic'>
                    <Link to={"https://www.linkedin.com/in/hardikraheja/"} >
                    <div className='pt-[.5rem] text-center'>
                        <div className='w-full whole_name'>
                            <div className='bg-white bg-opacity-70'>
                                <h3 text >Hardik Raheja</h3>
                                <p className='uppercase text-[#B70000]'>DEV/SOCIAL</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>

                  <div className='h-[200px] overflow-hidden flex flex-col-reverse w-[175px] shadow-[0_4px_4px_0px_#00000040] border-solid border-white border-2 rounded-[23px] m7pic'>
                    <Link to={"https://www.linkedin.com/in/adityamotwani4/"} >
                        <div className='pt-[.5rem] text-center'>
                            <div className='w-full whole_name'>
                                <div className='bg-white bg-opacity-70'>
                                    <h3 text >Aditya Motwani</h3>
                                    <p className='uppercase text-[#B70000]'>AI/ML</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                  </div>

             </div>
        </div>
        
    )
}

export default Mentor
