import React from 'react'

const timelineModule = ({timeline}) => {
  return (
    <div className='card timelineCard flex  flex-col text-center items-center'>
        <h2 className="uppercase">{timeline.date}</h2>
        <h4 className='text-xl'>{timeline.title}</h4>
        <p className="text-base ">{timeline.description}</p>
    </div>
  )
}

export default timelineModule