import React from 'react'
import { Link } from 'react-router-dom';
import CountDown from '../components/countDown';
import { useAuth } from '../context/AuthContext';

function Home({targetDate}) {
    const [ auth, setAuth ] = useAuth();

    function handleLogout() {
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <div className='flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3'>
            
            <div className='bigCard'>
                <div className='pt-6 pl-8'>
                    <h1 className='text-2xl'>Hello {auth.userName} !</h1>
                    <h1 className='text-lg'>Team: </h1>
                    <h1 className='text-2xl'>{auth.teamName}</h1>
                </div>
            </div>
            <CountDown targetDate={targetDate} />
            <Link to="https://drive.google.com/file/d/1Zp6fxeH_dd-A5RKsiZ08zWIe3dK6RO6e/view?usp=sharing">
                <div className='card'>
                    Problem Statement
                </div>
            </Link>
            <Link to="/user/timeline">
                <div className='card'>
                    Timeline
                </div>
            </Link>
            <Link to="/user/food">
                <div className='card'>
                    Food
                </div>
            </Link>
            <Link onClick={()=>window.location.href="/game.html"}>
                <div className='card'>
                    Games
                </div>
            </Link>
            <Link to="/user/others">
                <div className='card'>
                    Others
                </div>
            </Link>
            <button className='card-button' onClick={handleLogout}>
                Logout
            </button>
        </div>
    )
}

export default Home
