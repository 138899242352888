import React from 'react'
import { useState } from 'react';
import { baseUrl } from '../api';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';

import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

function Login() {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [auth, setAuth] = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [type, setType]=useState('password');
    const [icon, setIcon]=useState(eyeOff);

    const handleToggle=()=>{
        if(type==='password'){
            setIcon(eye)
            setType('text')
        }
        else{
            setIcon(eyeOff)
            setType('password')
        }
    }

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
      // Function to update window height
      const updateWindowHeight = () => {
        setWindowHeight(window.innerHeight);
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateWindowHeight);
  
      // Cleanup: remove event listener when component unmounts
      return () => {
        window.removeEventListener('resize', updateWindowHeight);
      };
    }, []); 


    const login = async (email, password) => {
        setLoading(true);
        try {
            // Make API call to your authentication endpoint
            const response = await axios.post(baseUrl + '/login', {
                email: email,
                password: password
            });
    
            if (response.status === 200) {
                const user = response.data;
                localStorage.setItem('auth', JSON.stringify(user));
                window.location.reload();
            } else {
                // Handle unsuccessful login (e.g., display an error message)
                setLoading(false);
                console.error('Login failed');
            }
        } catch (error) {
            // Handle network or other errors
            setLoading(false);
            console.error('Error during login:', error);
        }
    };
    
    const handleLogin = () => {
        // Call the login function from the AuthContext
        if (email === '' || password === ''){
            alert('Please fill all the fields');
            return;
        }
        login(email, password);
    };

    useEffect(() => {
        if (auth?.token && auth?.isAdmin !== true){
            navigate('/user');
        } else if (auth?.token && auth?.isAdmin === true){
            navigate('/admin');
        }
    }, [auth?.token]);

    return (
        <div className='h-[100vh] flex flex-col pt-28 items-center gap-4 text-3xl bg-[#AB433A]'>
            <div className='flex flex-col font-dark' style={{ fontFamily: 'Days One, sans-serif' }}>
                <span className='font-bold text-4xl'>
                    Welcome
                </span>
                <span className='text-base font-dark'>
                    Let's Go Hacking
                </span>
            </div>
            <div className='w-96 gap-4 flex flex-col text-md items-center mt-20 z-10'>
                <div>
                    <input 
                    type="email" 
                    name='email' 
                    id='email' 
                    placeholder='Email' 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='w-78'/>
                </div>
                <div>
                    <input 
                    type={type} 
                    name="password" 
                    id="password" 
                    placeholder='Password' 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className=''  />
                    <span className='eyeicon' onClick={handleToggle}><Icon icon={icon} size={30}/></span>
                </div>
                {!loading &&
                    <div>
                        <button
                        type='submit'
                        onClick={handleLogin}
                        className='login-button' >
                            Login
                        </button>
                    </div>
                }
                <HashLoader
                    color={'#2F2223'}
                    loading={loading}
                    // cssOverride={override}
                    size={40}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
            {windowHeight>465 && <img
            src="/img/login-bottom.png" alt="" 
            className='absolute bottom-0 w-full sm:h-[375px] treeback'/>}
        </div>
    )
}

export default Login
