import React from 'react'

function ScreenSizeError() {
    return (
        <div className='flex text-7xl justify-center border m-24 py-24 card-color border-none rounded-lg'>
            Use a Mobile Device
        </div>
    )
}

export default ScreenSizeError
