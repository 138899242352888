// src/Countdown.js
import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const calculateTimeRemaining = () => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      clearInterval(intervalId);
      setTimeRemaining({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeRemaining({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const intervalId = setInterval(calculateTimeRemaining, 1000);

  useEffect(() => {
    calculateTimeRemaining();
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-center mt-3">
      <div className="countdown flex flex-row text-3xl font-bold mb-2 gap-4 text-[#837970]">
        <div className='flex flex-col bg-[#AB433A42] w-24 h-20 justify-center rounded-xl '>
          <span className='text-3xl'>
            {String(timeRemaining.hours).padStart(2,'0')}
          </span>
          <span className='text-lg'> 
            Hours  
          </span>
        </div>
        <div className='flex flex-col bg-[#AB433A42] w-24 h-20 justify-center rounded-xl'>
          <span className='text-3xl'>
            {String(timeRemaining.minutes).padStart(2, '0')}
          </span>
          <span className='text-lg'>
            Minutes
          </span>
        </div>
        <div className='flex flex-col bg-[#AB433A42] w-24 h-20 justify-center rounded-xl '>
          <span className='text-3xl'>
            {String(timeRemaining.seconds).padStart(2, '0')}
          </span>
          <span className='text-lg'>
            Seconds
          </span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
