import React from 'react'

function fourOfour() {
    return (
        <div class="h-full flex flex-col items-center">
            <img src="/img/404.svg" alt="error" width={700} height={700} />
        </div>
    )
}

export default fourOfour
