import React from 'react'

function Games() {
    return (
        <div className='flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3'>
        </div>
    )
}

export default Games
