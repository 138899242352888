import React from 'react'
import Timelinemodule from "./timelineModule"


const timelineData = [
    {date:"30th Jan",time:"",title:"Release of Problem Statements",description:"Participants will select their preferred development focus in the hackathon as problem statements will be released for consideration."},
    {date:"31st Jan",time:"9:30 am",title:"Opening Cermeony - TSEC HACKS'24",description:"The hackathon's opening ceremony will commence by our honourable cheif guests."},
    {date:"31st Jan",time:"11:00 am",title:"Hackathon BEGINS!",description:"Hackathon will begin. Launching a creative and collaborative event for participants to showcase their innovative solutions and skills."},
    {date:"31st Jan",time:"06:30 pm",title:"Mentoring Round - I",description:"Guidance and support shall be provided to participants during the mentoring round at 06:30 pm for effective hackathon project development."},
    {date:"01st Feb",time:"11:00 am",title:"HACKATHON ENDS",description:"Hackathon concludes, showcasing innovative projects and celebrating participants' creativity, collaboration, and problem-solving prowess."},
    {date:"01st Feb",time:"11:30 am",title:"Judging Round - I & II",description:"Experts evaluate projects in judging rounds, assessing creativity, functionality, and innovation to determine winners."},
    {date:"01st Feb",time:"03:00 pm",title:"Final Results",description:"Winners announced, unveiling outstanding projects, and distributing prizes in recognition of participants' achievements."},
]

function TimeLine() {
    return (
        <div className='flex timeline-elements flex-col justify-center items-center text-3xl pt-6 px-3'>
            {
                timelineData.map((i)=> 
                   (<Timelinemodule timeline={i} key={i.title} /> ) )             
                
            }
        </div>
    )
}

export default TimeLine
