import React from 'react'
import { Link } from 'react-router-dom';
import CountDown from '../../components/countDown';

function Home({targetDate}) {

    function handleLogout() {
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <div className='flex flex-col justify-center items-center gap-4 text-3xl pt-6 px-3'>
            <div className='bigCard'>
                <div className='pt-6 pl-8'>
                    <h1 className='text-2xl'>Hello Admin !</h1>
                    <h1 className='text-lg'>Please do some work </h1>
                </div>
            </div>
            <CountDown targetDate={targetDate} />
            <Link to="/user/problemstatement">
                <div className='card'>
                    Problem Statement
                </div>
            </Link>
            <Link to="/user/timeline">
                <div className='card'>
                    Timeline
                </div>
            </Link>
            <Link to="/admin/adminfood">
                <div className='card'>
                    Food
                </div>
            </Link>
            <Link to="/user/others">
                <div className='card'>
                    Others
                </div>
            </Link>
            <button className='card-button' onClick={handleLogout}>
                Logout
            </button>
        </div>
    )
}

export default Home
